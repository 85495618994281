<template>
  <div>
    <div class="container">
      <div class="person_personInfo">
        <img
          class="person_avatar"
          v-if="detail.gender == '1'"
          src="../../assets/user_1.png"
          alt=""
        />
        <img
          class="person_avatar"
          v-else-if="detail.gender == '2'"
          src="../../assets/user_2.png"
          alt=""
        />
        <img
          class="person_avatar"
          v-else
          src="../../assets/nosexAvatar.jpg"
          alt=""
        />
        <div>
          <div class="person_username">{{ detail.name }}</div>
          <div class="person_userInfo">
            性别：{{
              detail.gender == 1 ? "男" : detail.gender == 2 ? "女" : "保密"
            }}
            | 年龄：{{ detail.age }}岁
          </div>
        </div>
      </div>
      <div class="resumeDetailTitle">个人信息</div>
      <div class="detail_list1">
        <div class="detail_list1Item">
          身高：<span>{{
            Number(detail.height) > 0 ? detail.height + "cm" : "保密"
          }}</span>
        </div>
        <div class="detail_list1Item">
          体重：<span>{{
            Number(detail.weight) > 0 ? detail.weight + "kg" : "保密"
          }}</span>
        </div>
        <div class="detail_list1Item">
          当前所在城市：<span>{{ detail.current_location }}</span>
        </div>
        <div class="detail_list1Item">
          当前职称：<span>{{ detail.current_job_title }}</span>
        </div>
        <div class="detail_list1Item">
          当前薪水上限(月)：<span>
            {{
              Number(detail.current_salary_max) > 0
                ? detail.current_salary_max
                : "保密"
            }}</span
          >
        </div>
        <div class="detail_list1Item">
          当前薪水下限(月)：<span>{{
            Number(detail.current_salary_min) > 0
              ? detail.current_salary_min
              : "保密"
          }}</span>
        </div>
        <div class="detail_list1Item">
          期望薪水下限(月)：<span>{{
            Number(detail.expected_salary_min) > 0
              ? detail.expected_salary_min
              : "保密"
          }}</span>
        </div>
        <div class="detail_list1Item">
          期望薪水上限(月)：<span>{{
            Number(detail.expected_salary_max) > 0
              ? detail.expected_salary_max
              : "保密"
          }}</span>
        </div>
      </div>
      <div style="padding: 0.3rem 0">
        <div class="detail_list1Item" style="width: 100%">
          当前从事行业：<span>{{
            detail.current_industry ? detail.current_industry : "暂无"
          }}</span>
        </div>
        <div class="detail_list1Item" style="width: 100%">
          当前所在公司：<span>{{ detail.current_company }}</span>
        </div>
      </div>
    </div>
    <!-- 候选人掌握技能 -->
    <div class="container" style="margin-top: 0.25rem; padding: 0.3rem">
      <div class="resumeDetailTitle">候选人掌握技能</div>
      <div class="abilityList">
        <div
          class="abilityItem"
          v-for="(item, index) in skillList"
          :key="index"
        >
          {{ item.tag_name }}
        </div>
      </div>
    </div>
    <!-- 学历信息 -->
    <div class="container" style="margin-top: 0.25rem; padding: 0.3rem">
      <div class="resumeDetailTitle">学历信息</div>
      <div v-if="educational_experience.length > 0">
        <div v-for="(item, index) in educational_experience" :key="index">
          <div
            class="detail_list1Item"
            style="width: 100%; margin-top: 0.25rem"
          >
            教育经历时间：<span
              >{{ item.education_experience_start_time }}～{{
                item.education_experience_end_time
              }}</span
            >
          </div>
          <div class="detail_list1" style="border-bottom: none; padding: 0">
            <div class="detail_list1Item">
              毕业院校：<span>{{ item.institution_name }}</span>
            </div>
            <div class="detail_list1Item">
              所学专业：<span>{{ item.education_field }}</span>
            </div>
          </div>
          <div class="detail_list1" style="border-bottom: none; padding: 0">
            <div class="detail_list1Item">
              毕业学校排名：<span>{{ item.institution_ranking }}</span>
            </div>
            <div class="detail_list1Item">
              学历：<span>{{ getEducation_level(item.education_level) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="margin: 0.25rem 0">暂无数据</div>
    </div>
    <!-- 工作经历 -->
    <div class="container" style="margin-top: 0.25rem; padding: 0.3rem">
      <div class="resumeDetailTitle">工作经历</div>
      <div v-if="work_resume.length > 0">
        <div v-for="(item, index) in work_resume" :key="index">
          <div class="companyName">{{ item.company_name }}</div>
          <div class="detail_list1Item" style="width: 100%">
            工作时间：<span
              >{{ item.work_experience_start_time }}～{{
                item.work_experience_end_time
              }}</span
            >
          </div>
          <div class="detail_list1" style="border-bottom: none; padding: 0">
            <div class="list1Item">
              工作职能：<span>{{
                item.job_function ? item.job_function : "暂无"
              }}</span>
            </div>
            <div class="list1Item" style="margin-left: 0.25rem">
              当前职称：<span>{{ item.job_title }}</span>
            </div>
          </div>
          <div class="detail_list1Item">工作描述：</div>
          <div class="content">{{ item.job_description }}</div>
        </div>
      </div>
      <div v-else style="margin: 0.25rem 0">暂无数据</div>
    </div>
    <!-- 项目经历 -->
    <div class="container" style="margin-top: 0.25rem; padding: 0.3rem">
      <div class="resumeDetailTitle">项目经历</div>
      <div v-if="projectList && projectList.length > 0">
        <div v-for="(item, index) in projectList" :key="index">
          <div class="companyName">{{ item.project_name }}</div>
          <div class="list1Item" style="width: 100%">
            项目时间：<span v-if="item.project_experience_start_time">{{ item.project_experience_start_time }}～{{
                item.project_experience_end_time
              }}</span>
              <span v-else>暂无</span>
          </div>
          <div class="list1Item">项目内容：</div>
          <div class="content">{{ item.project_description }}</div>
          <!-- <div class="list1Item">项目职责：</div>
          <div class="content">编写所负责模块的开发计划、开发实例、验收报告,执行开发，测试执行过程中使用 Postman 做接口测试,Charles、Fiddler 调试代理工具协定位问题,记录并跟踪 bug 状态。 </div>  -->
        </div>
      </div>
    </div>
    <!-- 专业技能 -->
    <div class="container" style="margin-top: 0.25rem; padding: 0.3rem">
      <div class="resumeDetailTitle">专业技能</div>
      <div class="list1Item" style="margin: 0.2rem 0">专业技能：</div>
      <div class="content">
        {{ detail.skills ? detail.skills : "暂无数据" }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {},
      skillList: [],
      educational_experience: [],
      work_resume: [],
      projectList: [],
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$http
        .post("/firm/v1/record/viewPostDetails", {
          reqType: "record",
          base_id: this.$route.query.id,
        })
        .then((res) => {
          let obj = JSON.parse(res.data).data;
          console.log(obj);
          this.detail = obj;
          this.skillList = JSON.parse(obj.assessment).skills_tags;
          if (obj.education_experience) {
            this.educational_experience = JSON.parse(obj.education_experience);
          }
          if (obj.work_experience) {
            this.work_resume = JSON.parse(obj.work_experience);
          }
          if (obj.project_experience) {
            this.projectList = JSON.parse(obj.project_experience);
          }
        });
    },
    // 所学专业
    getEducation_level(type = 0) {
      let str;
      switch (Number(type)) {
        case 0:
          str = "高中以下";
          break;
        case 1:
          str = "高中";
          break;
        case 2:
          str = "大专";
          break;
        case 3:
          str = "本科";
          break;
        case 4:
          str = "硕士";
          break;
        case 5:
          str = "博士";
          break;
        case 6:
          str = "EMBA";
          break;
        case 7:
          str = "MBA";
          break;
      }
      return str;
    },
  },
};
</script>
<style scoped>
.container {
  background: #fff;
  padding: 0 0.3rem;
}
.person_personInfo {
  display: flex;
  justify-content: left;
  padding: 0.3rem 0;
}
.resumeDetailTitle {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #696b6f;
  position: relative;
  padding-left: 0.15rem;
}
.resumeDetailTitle::after {
  content: "";
  display: block;
  width: 0.08rem;
  height: 0.3rem;
  background-color: red;
  margin-left: -0.15rem;
  transform: translateX(-50%);
  background: linear-gradient(270deg, #fc9c45 0%, #fe4e04 100%);
  border-radius: 0.06rem;
  margin-top: -0.35rem;
}
/* .list1{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding:0.3rem 0;
  border-bottom: 0.01rem solid #EEEEEE;
}
.list1Item{
  line-height: 0.5rem;
  width:50%;
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #696B6F;
}
.list1Item span{
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242F57;
} */
.content {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242f57;
  padding-bottom: 0.2rem;
  /* border-bottom:0.01rem solid #eee */
}
.abilityList {
  padding-top: 0.5rem;
}
.companyName {
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 0.3rem 0 0.1rem 0;
}
</style>